import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Company } from '../../../models/company.model';
import { GeoSelection } from '../../../models/geo-selection.model';
import { Product } from '../../../models/product.model';
import { Destination } from '../../../models/slots-batch.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { CurrentDateService } from '../../../services/current-date.service';
import { DestinationsService } from '../../../services/destinations.service';
import { HubSpotService } from '../../../services/hub-spot.service';
import { IntercomService } from '../../../services/intercom.service';
import { MarketService } from '../../../services/market.service';
import { startDay } from '../../../utilities/date';
import { genCropList } from '../../../utilities/setProp';
import { Negotiation } from '../../modules/commercial/models/negotiation.model';
import { Order } from '../../modules/commercial/models/order.model';
import { OrderService } from '../../modules/commercial/services/order.service';

@Component({
  selector: 'app-create-cd',
  templateUrl: './create-cd.component.html',
  styleUrls: ['./create-cd.component.scss']
})
export class CreateCdComponent implements OnInit, OnDestroy {

  /** Certificate of deposit. */
  public cd: Order;
  public company: Company;
  /** List of crop years. */
  public cropList: string[];
  public destinations: Destination[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public previewMode: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public products: Product[];
  public relatedNegotiation: Negotiation;
  public today: Date;

  private quantityTypes: any[];
  private subscriptions: Subscription[] = [];

  constructor(
    private destinationsService: DestinationsService,
    public companyService: CompanyService,
    private componentComm: ComponentCommService,
    private currentDate: CurrentDateService,
    private intercomService: IntercomService,
    private marketService: MarketService,
    private orderService: OrderService,
    public hubSpotService: HubSpotService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.loading = true;
    this.cd = new Order();
    this.today = this.currentDate.get();
    this.cropList = genCropList();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().pipe(
      tap(company => {
        this.company = company;
      }),
      switchMap(company => {
        return this.route.data.pipe(
          tap((data: { negotiation: Negotiation }) => {
            if (data.negotiation) {
              this.relatedNegotiation = data.negotiation;
            }
          })
        );
      })
    ).subscribe(() => {
      if (this.company) this.loadMarketData();
    }));
    this.subscriptions.push(this.marketService.watchProducts().subscribe(response => {
      if (response) this.products = response.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));

    this.componentComm.emit({ name: 'app-title', title: 'ORDER.NEW_CERTIFICATE' });
  }

  private loadMarketData(): void {
    this.loading = true;
    this.subscriptions.push(this.orderService.getQuantityTypes().subscribe(quantityTypes => {
      this.marketDataLoaded(quantityTypes);
    }));
    this.subscriptions.push(this.destinationsService.get(this.company.id).subscribe(destinations => {
      this.destinations = destinations;
    }));
  }

  private marketDataLoaded(quantityTypes: any[]): void {
    // Set default values here

    // Products
    // Product selection workaround
    // Product object is retrieved with minimal data, this populates the attribute with the complete object
    if (this.cd.product) this.cd.product = this.products.filter(product => product.id === this.cd.product.id)[0];

    // Quantity types
    this.quantityTypes = quantityTypes;
    if (!this.cd.business_detail.quantity.type) {
      this.cd.business_detail.quantity.type = this.quantityTypes[0];
    }

    // Delivery places
    this.cd.business_detail.delivery.locations = [];

    this.createNewCD();

    this.loading = false;
  }

  private createNewCD(): void {
    // CD are represented as SALES from this.company
    this.cd.operation_type = 'venta';

    if (this.relatedNegotiation &&
      // Company is a buyer
      (this.relatedNegotiation.buyer.id === this.company.id ||
        this.relatedNegotiation.buyer_represented.find(company => company.id === this.company.id))) {
      // This will be binded to a Negotiation

      // Product
      this.cd.product = this.relatedNegotiation.order.product;

      // Quality
      this.cd.product_detail = this.relatedNegotiation.proposal.product_detail;

      // Quantity
      this.cd.business_detail.quantity = this.relatedNegotiation.proposal.business_detail.quantity;
    } else this.relatedNegotiation = undefined;

    this.cd.type = {
      id: 8,
      name: "Certificate of Deposit",
      slug: "certificate_of_deposit"
    };
  }

  public changeProduct(): void {
    this.cd.product_detail.quality = {};

    for (let i in this.cd.product.attributes.quality) {
      let attribute = this.cd.product.attributes.quality[i];
      if (attribute.element !== "textarea") {
        if (attribute.empty) {
          this.cd.product_detail.quality[i] = attribute.empty;
        } else {
          this.cd.product_detail.quality[i] = attribute.values[0];
        }
      } else {
        this.cd.product_detail.quality[i] = attribute.value;
      }

      if (attribute.order) {
        this.cd.product_detail.quality[i].order = attribute.order;
      }
    }

    // Default
    this.cd.business_detail.quantity.unit = this.cd.product.quantity_units[0];
  }

  /**
   * Sets delivery range, from today to `days` days after.
   */
  public setDeliveryRange(days: number = 45): void {
    const today_start = startDay(this.today);

    this.cd.business_detail.delivery.range = [
      today_start,
      new Date(today_start.getTime() + (days * 24 * 60 * 60 * 1000))
    ];
  }

  public destinationChanged(): void {
    // Only for the purpose of normalizing the order data, but generates redundant information
    // It may not be necessary in the future
    const geoselection: GeoSelection = new GeoSelection(null, this.cd.destination.location);
    this.cd.business_detail.delivery.locations = [geoselection];
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public cancel(): void {
    this.router.navigate(['/company', this.company.id, 'company-traded-orders'], {
      queryParams: {
        order_by: '-original_booking_date',
        'filters[settled]': false
      }
    });
  }

  public create(): void {
    this.processing = true;

    this.subscriptions.push(this.orderService.create(this.cd, this.company.id).subscribe(order => {
      this.intercomService.track('cd-created', {
        order_id: order.id,
        company_id: this.company.id,
        company_name: this.company.name,
        product_name: this.cd.product.name
      });
      this.cancel();
      // this.processing = false;
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
